import React, { useState, useEffect } from 'react';
import DragComponent from '../components/DragComponent';
import { useTranslation } from "react-i18next";
import { icons } from '../helpers/constants';
import * as request from "../services/FetchService";
import Button from '../components/Buttons/Button';
import { useIsMobile } from '../helpers/customHooks';
import DistanceGraph from '../components/Graphs/DistanceGraph';
import SpeedGraph from '../components/Graphs/SpeedGraph';
import Co2Graph from '../components/Graphs/Co2Graph';
import TimeGraph from '../components/Graphs/TimeGraph';
import { useContextData } from '../components/ContextData';


const Statistics = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [toggleDrag, setToggleDrag] = useState(false)
    const [statsFull, setStatsFull] = useState()
    const [statsFiltered, setStatsFiltered] = useState()
	const { deviceListCompact, setDeviceListCompact } = useContextData();

    const [selectedDevice, setSelectedDevice] = useState(-1)

    const [summary, setSummary] = useState()
    const [measurement, setMeasurement] = useState()
    const [speedType, setSpeedType] = useState("top")
    const [co2Type, setCo2Type] = useState("car")
    const getCurrentMonthFormatted = () => {
        const date = new Date(); // Current date
        const year = date.getFullYear(); // Extract the year
        const month = (`0${date.getMonth() + 1}`).slice(-2); // Extract the month and ensure it is two digits
        return `${year}-${month}`;
    };
    const [currentDate, setCurrentDate] = useState(getCurrentMonthFormatted())
    const [lastFetchedYear, setLastFetchedYear] = useState(null);
    const [devices, setDevices] = useState([])



    useEffect(() => {
        getStats()
        getSummary()
        getDevices()
    }, [])


    const getDevices = () => {
		if(deviceListCompact.length === 0){
			request.get("devices/compactinfo").then((response) => {
				setDeviceListCompact(response.data.devices)
				//setDeviceListFull(response.data.devices)
				//setDeviceList(response.data.devices);
			});
		}else{
			setDevices(deviceListCompact)
		}
	};

    useEffect(() => {
        setDevices(deviceListCompact)
    }, [deviceListCompact])

    const getStats = (year = null, deviceIds = []) => {
        if((year && year === lastFetchedYear) && (deviceIds.length === 0 || deviceIds[0] === parseInt(selectedDevice))){return}
        let data = {};
        if (year) {
            data.year = year;
        }
        if (deviceIds.length > 0 && deviceIds[0] !== -1) {
            data.deviceid = deviceIds;
        }
        request.post("", "statistic/all", data).then((response) => {
            setStatsFull(response.data.response);
            setLastFetchedYear(year)
        });
    };

    useEffect(() => {
        if (statsFull) {
            const filteredData = statsFull.filter(item => {
                const [itemYear, itemMonth] = item.date.split('-');
                const [currentYear, currentMonth] = currentDate.split('-');
                return parseInt(itemYear, 10) === parseInt(currentYear, 10) && parseInt(itemMonth, 10) === parseInt(currentMonth, 10);
            });

            const currentYear = currentDate.split("-")[0];
            const isYearChangeOrNoData = filteredData.length === 0 || !statsFull.some(item => item.date.startsWith(currentYear));

            if (isYearChangeOrNoData) {
                getStats(currentYear);
                setStatsFiltered(filteredData);
            } else {
                setStatsFiltered(filteredData);
            }
        }
    }, [statsFull, currentDate, lastFetchedYear]);

    const getSummary = () => {
        request.post("", "statistic/all/summery").then((response) => {
            setSummary(response.data.response)
            setMeasurement(response.data.measurement)
        })   
    }

    const selectType = (variant, event) => {
        if(variant === "speed"){
            setSpeedType(event)
        }else{
            setCo2Type(event)
        }
    }

    const changeDate = (value) => {
        // Parse the current date from the "YYYY-MM" format
        const [year, month] = currentDate.split('-').map(num => parseInt(num, 10));
    
        // Create a new Date object from the current year and month
        const date = new Date(year, month - 1);
    
        // Adjust the month by the value
        date.setMonth(date.getMonth() + value);
    
        // Get today's date for comparison
        const today = new Date();
        // Remove the time component for a fair comparison
        today.setHours(0, 0, 0, 0);
    
        // Prevent the date from going beyond the current month
        if (date > today) {
            return; // Exit the function without updating
        }
    
        // Format the new date back into "YYYY-MM" format
        const updatedYear = date.getFullYear();
        const updatedMonth = (`0${date.getMonth() + 1}`).slice(-2); // Ensure two digits
    
        // Set the new date
        setCurrentDate(`${updatedYear}-${updatedMonth}`);
    };

    const calculateTime = (time) => {
        // Initialize days, hours, and minutes
        let days = Math.floor(time / (60 * 24));
        let hours = Math.floor((time % (60 * 24)) / 60);
        let minutes = Math.round(time % 60);
      
        // Constructing the time string based on the values of days, hours, and minutes
        let timeString = '';
        if (days > 0) timeString += `${days} d `;
        if (hours > 0) timeString += `${hours} h `;
        if (minutes > 0 || timeString === '') timeString += `${minutes} m`;
      
        return timeString.trim();
      };


      const selectDevice = (id) => {
        setSelectedDevice(id);
        const currentYear = currentDate.split("-")[0];
    
        // This condition checks if id is not -1, and fetches stats for that id.
        if (id !== "-1") {
            getStats(currentYear, [parseInt(id)]);
        } else {
            // When id is -1, implying no specific device selected, it fetches without deviceid.
            // Notice the removal of parentheses around (currentYear, []) which seemed to be a mistake.
            getStats(currentYear, [-1]);
        }
    }
    return(
        <>
		<div className={`d-flex flex-column px-3 w-100 scrollable-y ${MOBILE ? "py-5" : ""}`}>
			<div className="d-flex flex-row mt-4 align-items-center page_header">
                    <img src={icons.sidebar.statistics_active} className="title_icon primary-icon" alt="..." />
                    <h1 className="h1 primary ms-3">{t("statistics.statistics")}</h1>
				</div>
                <div className="d-flex flex-row mt-3 align-items-center w-50">
                    <select
                        onChange={(event) => selectDevice(event.target.value)}
                        value={selectedDevice}
                        className={`form-control bg-grey ${MOBILE ? "w-50" : "w-25"}`}
                    >
                    <option value={-1}>{t("statistics.all_devices")}</option>
                    {devices.map((device, index) => {
                        return(
                            <option key={index} value={device.id}>{device.name}</option>
                            )
                    })}
                    </select>
                    <div className="d-flex justify-content-start mt-1 ms-3">
                        <img src={icons.back} className="cursor-pointer" alt="" onClick={() => changeDate(-1)}/>
                            <h4 className="mx-2 bold">{currentDate}</h4>
                        <img src={icons.back} className="rotate-180 cursor-pointer"  alt="" onClick={() => changeDate(1)}/>
                    </div>
                </div>
                <div className="row m-0 p-0 mt-3">

                    {/* SPEED */}
                    <div className="col-md-3 col-sm-12 pe-3">
                        <div className="card mt-3 px-4 py-3 d-flex flex-column justify-content-between border-12 border-0 shadow-small">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <h2>{t("statistics.speed")}</h2>
                                </div>
                                <div className="d-flex flex-column w-50 align-items-end">
                                    <select
                                        onChange={(event) => selectType("speed", event.target.value)}
                                        value={speedType}
                                        className={`form-control bg-grey w-auto pe-5`}
                                    >
                                        <option value={"top"}>{t("statistics.top_speed")}</option>
                                        <option value={"average"}>{t("statistics.average_speed")}</option>
                                    </select>
     
                                </div>
                            </div>
                            <SpeedGraph data={statsFiltered} type={speedType} />
                            <div className="row m-0 p-0 border-12 shadow overflow-hidden mt-3">
                                <div className="col-4 bg-primary p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={icons.tachometer} className="white-icon size_25" alt="" />
                                        <p className="mt-1">{t("statistics.speed")}</p>
                                    </div>     
                                </div>
                                <div className="col-4 p-3 section-divider">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p className="text-truncate">{t("statistics.average_per_ride")}</p>
                                        <p className="bold mt-2">{summary?.speed_average_per_ride?.toFixed(0) ?? 0} {measurement?.speed_measurement}</p>
                                    </div>
                                </div>
                                <div className="col-4 p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p>{t("statistics.max_speed")}</p>
                                        <p className="bold mt-2">{summary?.speed_max?.toFixed(0) ?? 0} {measurement?.speed_measurement}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DISTANCE */}
                    <div className="col-md-3 col-sm-12 px-3">
                        <div className="card mt-3 px-4 py-3 d-flex flex-column justify-content-between border-12 border-0 shadow-small">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <h2>{t("statistics.distance")}</h2>
                                </div>
                            </div>
                            <DistanceGraph data={statsFiltered} />
                            <div className="row m-0 p-0 border-12 shadow overflow-hidden mt-3">
                                <div className="col-4 bg-primary p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={icons.sidebar.history_active} className="white-icon size_25" alt="" />
                                        <p className="mt-1">{t("statistics.distance")}</p>
                                    </div>     
                                </div>
                                <div className="col-4 p-3 section-divider">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p className="text-truncate">{t("statistics.average_per_ride")}</p>
                                        <p className="bold mt-2">{summary?.distance_average_per_ride?.toFixed(1) ?? 0} {measurement?.distance_measurement}</p>
                                    </div>
                                </div>
                                <div className="col-4 p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p>{t("statistics.total")}</p>
                                        <p className="bold mt-2">{summary?.distance_total?.toFixed(1) ?? 0} {measurement?.distance_measurement}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 ps-3">
                        <div className="card mt-3 px-4 py-3 d-flex flex-column justify-content-between border-12 border-0 shadow-small">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <h2>{t("statistics.co2")}</h2>
                                </div>
                                <div className="d-flex flex-column w-50 align-items-end">
                                    <select
                                        onChange={(event) => selectType("co2", event.target.value)}
                                        value={co2Type}
                                        className={`form-control bg-grey w-auto pe-5`}
                                    >
                                        <option value={"car"}>{t("statistics.car")}</option>
                                        <option value={"train"}>{t("statistics.train")}</option>
                                        <option value={"plane"}>{t("statistics.plane")}</option>

                                    </select>
                                </div>
                            </div>
                            <Co2Graph data={statsFiltered} type={co2Type} />
                            <div className="row m-0 p-0 border-12 shadow overflow-hidden mt-3">
                                <div className="col-4 bg-primary p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={icons.tachometer} className="white-icon size_25" alt="" />
                                        <p className="mt-1">{t("statistics.co2")}</p>
                                    </div>     
                                </div>
                                <div className="col-4 p-3 section-divider">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p className="text-truncate">{t("statistics.average_per_ride")}</p>
                                            <p className="bold mt-2">
                                                {co2Type === "car"
                                                    ? summary?.co2_saved_vs_car_average_per_ride?.toFixed(1) ?? 0
                                                    : co2Type === "train"
                                                    ? summary?.co2_saved_vs_train_average_per_ride?.toFixed(1) ?? 0
                                                    : summary?.co2_saved_vs_airplan_average_per_ride?.toFixed(1) ?? 0
                                                } {measurement?.co2_measurement}
                                            </p>
                                    </div>
                                </div>
                                <div className="col-4 p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p>{t("statistics.total")}</p>
                                        <p className="bold mt-2">
                                            {co2Type === "car"
                                                ? summary?.co2_saved_vs_car?.toFixed(1) ?? 0
                                                : co2Type === "train"
                                                ? summary?.co2_saved_vs_train?.toFixed(1) ?? 0
                                                : summary?.co2_saved_vs_airplan?.toFixed(1) ?? 0
                                            } {measurement?.co2_measurement}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-3 px-4 py-3 d-flex flex-column justify-content-between border-12 border-0 shadow-small">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <h2>{t("statistics.time")}</h2>
                                </div>
                            </div>
                            <TimeGraph data={statsFiltered} />
                            <div className="row m-0 p-0 border-12 shadow overflow-hidden mt-3">
                                <div className="col-4 bg-primary p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={icons.clock} className="white-icon size_25" alt="" />
                                        <p className="mt-1">{t("statistics.time")}</p>
                                    </div>     
                                </div>
                                <div className="col-4 p-3 section-divider">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p className="text-truncate">{t("statistics.average_per_ride")}</p>
                                        <p className="bold mt-2">{calculateTime(summary?.time_average_per_ride)}</p>
                                    </div>
                                </div>
                                <div className="col-4 p-3">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <p>{t("statistics.total")}</p>
                                        <p className="bold mt-2">{calculateTime(summary?.time_total)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Statistics;