import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const TimeGraph = (props) => {
    const [fetchedData, setFetchedData] = useState([])
    const { t } = useTranslation();
    const [renderChart, setRenderChart] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
          setRenderChart(true);
        }, 100); // Adjust delay as needed
      
        return () => clearTimeout(timer);
      }, []);


    useEffect(() => {
        if((fetchedData.length === 0 || fetchedData[0].data.length === 0) && renderChart){
            setOptions((prevState) => ({
                ...prevState,
                xaxis: {
                    ...prevState.xaxis,
                    labels: {
                        ...prevState.xaxis.labels,
                        show: false, // Hides X-axis labels if no data
                    }
                },
                yaxis: {
                    ...prevState.yaxis,
                    show: false, // Hides Y-axis if no data
                },
                grid: {
                    ...prevState.grid,
                    show: false // Hides grid lines if no data
                },
            }));
        }else if(!options.yaxis.show){
            setOptions((prevState) => ({
                ...prevState,
                xaxis: {
                    ...prevState.xaxis,
                    labels: {
                        ...prevState.xaxis.labels,
                        show: true, // Hides X-axis labels if no data
                    }
                },
                yaxis: {
                    ...prevState.yaxis,
                    show: true, // Hides Y-axis if no data
                },
                grid: {
                    ...prevState.grid,
                    show: true // Hides grid lines if no data
                },
            }));   
        }

    }, [fetchedData])

    const [options, setOptions] = useState({
        chart: {
            // locales: [se, en, fi],
            // defaultLocale: localStorage.getItem("language")==="sv" ? "se": localStorage.getItem("language") ?? "en",
            id: "Bar1",
            foreColor: getComputedStyle(document.documentElement).getPropertyValue('--main-text-color-light'),
            toolbar: {
                autoSelected: 'none',
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        
        
        // markers: {
        // size: 5,
        // colors: ["#000524"],
        // strokeColor: "#00BAEC",
        // strokeWidth: 3
        // },
        xaxis: {
            show: true,
            type: "datetime",
            tooltip: {
                enabled: false
            }, 
            labels: {
                rotateAlways: true,
                formatter: (value, timestamp) => {
                    // Getting user's timezone
                    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    // Convert timestamp back to Date
                    const date = new Date(timestamp);
                    // Format date to display in user's timezone
                    return date.toLocaleTimeString('en-US', { timeZone: userTimezone, hour12: false, hour: '2-digit', minute: '2-digit' });
                }
            },
        },
        yaxis: {
            show: true,
            type: "numeric",
            tickAmount: 2,
            
            min: 0, // Sets the minimum value of the yAxis to 0
            labels: {
                offsetX: -16,
                formatter: (value) => {
                    return calculateTime(value)
                },
            }
        },
        grid: {
            show: true,
            padding: {
              left: -12,
            },
        },
        tooltip: {
            enabled: true,
            theme: 'light', // Choose 'light' or 'dark', or customize further
            x: {
                show: false, // Disable the x value display in the tooltip
            },
            y: {
                formatter: function (value) {
                    return calculateTime(value)
                },
                title: {
                    formatter: () => false,
                },
            },
            marker: {
                show: false, // Disable marker in tooltip
            },
            fixed: {
                enabled: false, // This can be adjusted based on your preference
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
            dashArray: 0
        },
        colors:['#000000'],  
        fill: {
            colors: ['#000000']
            // type: 'solid',
            // colors: ['transparent']
        },
        theme: {
            mode: 'light',
            palette: 'palette1',
            monochrome: {
                enabled: false,
                color: '#00000',
                shadeTo: 'dark',
                shadeIntensity: 0.5
            },
        },
        dataLabels: {
            enabled: false
        },
        noData: {
            text: t("statsistics.no_data"),
            align: "center",
            verticalAlign: "middle",
            style:{
                fontSize: '24px'
            }
          },
    })

    const calculateTime = (time) => {
        // Initialize days, hours, and minutes
        let days = Math.floor(time / (60 * 24));
        let hours = Math.floor((time % (60 * 24)) / 60);
        let minutes = Math.round(time % 60);
      
        // Constructing the time string based on the values of days, hours, and minutes
        let timeString = '';
        if (days > 0) timeString += `${days} d `;
        if (hours > 0) timeString += `${hours} h `;
        if (minutes > 0 || timeString === '') timeString += `${minutes} m`;
      
        return timeString.trim();
      };

    useEffect(() => {
        if(props.data){
            let type = "time_total"
            let data = props.data.map(item => { 
                let value = item[type] ? item[type] : 0                   
                return (
                    {
                        x: item.date,
                        y: value
                    }
                )
            })
            setFetchedData([{name: "Speed", data: data.map(item => item.y)}])
            setOptions((prevState) => ({
                ...prevState,
                chart: {
                    ...prevState.chart,
                    events: {
                        click: function(event, chartContext, { seriesIndex, dataPointIndex, config }) {
                        //    clickFunction(dataPointIndex)
                        }
                    },
                },
                xaxis: {
                    type: "datetime",
                    tooltip: {
                        enabled: false
                    },
                    categories: data.map(item => item.x), // Assuming your x values are the text labels you want
                    labels: {
                        formatter: (value, timestamp) => {
                            // Getting user's timezone
                            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            // Convert timestamp back to Date
                            const date = new Date(timestamp);
                            // Format date to display in user's timezone
                            return date.toLocaleDateString(localStorage.getItem("language") ? localStorage.getItem("language") : "en" , { timeZone: userTimezone, month: '2-digit', day: '2-digit' });
                        }
                    },
                },
            }))
        }
    }, [props.data])


    if(renderChart){
        return (
            <Chart
                options={options}
                series={fetchedData ? fetchedData : []}
                type="area"
                height={props.height ? props.height : "75%"}
            />
        );
    }


}

export default TimeGraph;