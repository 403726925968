import React, { useState, useEffect, useCallback, createRef, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, MarkerF, InfoBox, Polyline, MarkerClusterer, Polygon, Circle } from '@react-google-maps/api';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, markers } from "../../helpers/constants";
import * as request from "../../services/FetchService";
import { darkMode, lightMode } from './mapStyles';
import MapSettings from './MapSettings';
import { MapTogglesProvider, useMapToggles } from './ToggleContext';
import { useContextData } from '../ContextData';

import { fixDate, getCssVariableValue, getEventIconMap } from '../../helpers/helperFunctions';
import DeviceMarker from './DeviceMarker';
import { useIsMobile, useLocalStorage } from '../../helpers/customHooks';
import HistoryMarker from './HistoryMarker';
import EventMarker from './EventMarker';

// import {darkMode, lightMode} from "./mapStyles.js"
const containerStyle = {
	height: '100%',
	borderRadius: '4px'
  };
  const clusterStyles = [
	  {
		textColor: 'white',
		url: markers.cluster_marker,
		height: 45,
		width: 40,
		anchorText: [-5, 0],
		anchorIcon: [45, 20],
		fontFamily: "Roboto",
		textSize: 12,
		fontWeight: "bold",
	  },
	];
  const center = {
	lat: 59.30190008066058,
	lng: 18.104984453399872
  };
  let intervalId;

const Map = (props) => {
	const { 
		mapToggles, 
		activeDevice,
		setActiveDevice,
		activeEvent,
		setActiveEvent,
		activeGeo, 
		creatingGeo,
		setCreatingGeo, 
		contextGeoMethod,
		updateGeoData, 
		contextSaveGeofence, 
		initialGeoData,
		mapTypes,
		geofenceDeleted,
		setGeofenceDeleted,
		setGeofenceCreated,
		geofenceCreated,
		historyData,
		setHistoryData,
		routesData,
		setRoutesData,
		groupArray,
		routePoint
	} = useMapToggles(); // Access the mapToggles state
	// const showPolyLine = useMemo(() => calculatePolyLine(), [historyPolyLine, mode]);
	const { deviceListMap, setDeviceListMap } = useContextData();
	const [storage, setStorage] = useLocalStorage("mapsettings")
	const MOBILE = useIsMobile();
	const navigate = useNavigate();
	const location = useLocation();
	const [isOpen, setOpen] = useState(false)
	const [positions, setPositions] = useState([])
	const [devices, setDevices] = useState(null)
	const [events, setEvents] = useState(null)
	const [geofences, setGeofences] = useState([])
	const [map, setMap] = useState(null)
	const [mode, setMode] = useState(storage ? storage.maptype : "light")
	const [infoIndex, setIndex] = useState(null)
	const [toggle, setToggle] = useState(true)
	const [looping, setLooping] = useState(false)
	const [visiblePolyGeo, setVisiblePolyGeo] = useState(null)
	const [visibleCircleGeo, setVisibleCircleGeo] = useState(null)
	const polygonRef = useRef(null);
  	const listenersRef = useRef([]);
	const circleRef = useRef(null);
  	const listenersCircleRef = useRef([]);
	const [polyPathData, setPolyPathData] = useState();
	const [circleData, setCircleData] = useState();
	const [openMarker, setOpenMarker] = useState(-1)
	const [activeGroup, setActiveGroup] = useState(null)
	const [historyPolyLine, setHistoryPolyLine] = useState()
	const [closestHistoryPoint, setClosestHistoryPoint] = useState()
	const [openEventMarker, setOpenEventMarker] = useState(-1)
	const [showDeviceName, setShowDeviceName] = useState(false)
	const [cluster, setCluster] = useState(false)
	const [hideOffline, setHideOffline] = useState(false)
	const [traceUnit, setTraceUnit] = useState(false)
	const [tracingPositions, setTracingPositions] = useState([])
	const customColor = getCssVariableValue("--light_navy") ? getCssVariableValue("--light_navy") : "#000000"
	const customActiveColor = getCssVariableValue("--green") ? getCssVariableValue("--green") : "#000000"
	const [firstLoad, setFirstLoad] = useState(true)

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS_KEY
	})
	const options = {
		minZoom: 1,
		restriction: {
			latLngBounds: {
					east: 179.9999,
					north: 85,
					south: -85,
					west: -179.9999
				},
				strictBounds: true
		},
		// styles: mode==="dark"?darkMode:lightMode,
		clickableIcons: false,
		mapTypeId: mode==="satellite"
			? "satellite"
			: mode==="hybrid"
			? "hybrid"
			: mode==="terrain"
			? "terrain"
			: "roadmap",
		mapTypeControl: false,
		streetViewControl: false,
		keyboardShortcuts: false,
		fullscreenControl: false,
		disableDefaultUI: true,
		tilt:0,
		zoomControl: false,
		gestureHandling: "greedy",
		backgroundColor: "rgba(0,0,0,0)",
		styles: mode === "dark" ? darkMode : mode === "light" ? lightMode : null,
	}

	useEffect(() => {
		let activeMapType = mapTypes.find(item => item.active === true);
		if(activeMapType){
			setMode(activeMapType.id)
		}
		//setMode(activeMapType.id)
	}, [mapTypes])


	useEffect(() => {
		if(historyData){
			let points = historyData?.points.map(point => ({
				lat: point.latitude,
				lng: point.longitude,
				time: point.datetime,
				speed: point.speed,
				unit: historyData.speedlimitunit,
				sat: point.sat,
				event: point.alarm,
				charge: point.charge,
				ignition: point.ignition
			}));
			setHistoryPolyLine(points)
		}else{
			setHistoryPolyLine()
		}
	}, [historyData])

	useEffect(() => {
		if(routesData){
			let points = routesData.map(point => ({
				lat: point.latitude,
				lng: point.longitude,
				time: point.datetime,
				speed: point.speed
			}));
			setHistoryPolyLine(points)
		}else{
			setHistoryPolyLine()
		}
	}, [routesData])

	useEffect(() => {
		if(openMarker !== -1 && map){
			const specificDevice = devices.find(device => device.id === openMarker);
			if (specificDevice) {
				const pos = {
				  lat: specificDevice.latitude,
				  lng: specificDevice.longitude
				};
				if(map.getZoom() < 15){
					map.setZoom(15)
				}
				map.panTo(pos)
				map.panBy(0, 150)
			}
		}
	}, [openMarker])

	useEffect(() => {
		if(groupArray.length > 0){
			let filteredDevices = deviceListMap.filter(device => groupArray.includes(device.id));
			setDevices(filteredDevices)
		}else{
			setDevices(deviceListMap)
		}
	}, [groupArray, deviceListMap])

	const getDevices = () => {
		if(deviceListMap.length>0){
			setDevices(deviceListMap)
		}else{
			request.get("devices/info").then((response) => {
				if(!response.data || response.data.error || response.status !== 200){
					return
				}
				if(response.data){					
					setDeviceListMap(response.data)
				}
			});
		}
	};

	useEffect(() => {		
		if(firstLoad && devices?.length > 0){
			setBounds()
			setFirstLoad(false)
		}

	}, [devices, map])

	useEffect(() => {
		if(!devices){
			getDevices()
		}
	}, [])


	useEffect(() => {
		if (traceUnit && devices) {
			devices?.forEach((device) => {
				// Skip if lat or lng is null
				if (device.latitude == null || device.longitude == null) {
					return;
				}
	
				let pos = { lat: device.latitude, lng: device.longitude };
				setTracingPositions(prev => {
					// Initialize device's array if it doesn't exist
					const devicePositions = prev[device.id] || [];
	
					// Check if the current position is the same as the last position in the array
					const lastPos = devicePositions[devicePositions.length - 1];
					if (lastPos && lastPos.lat === pos.lat && lastPos.lng === pos.lng) {
						// If the position is the same, do not modify the state
						return prev;
					}
	
					// Add new position or replace the oldest if there are already 10 positions
					const newPos = devicePositions.length >= 10 ? [...devicePositions.slice(1), pos] : [...devicePositions, pos];
	
					return {
						...prev,
						[device.id]: newPos, // Update the specific device's positions
					};
				});
			});
		}else{
			setTracingPositions([])
		}
	}, [traceUnit, devices]);

	useEffect(() => {
		if(geofenceDeleted || geofenceCreated){
			setTimeout(() => {
				setGeofenceDeleted(false)
				setGeofenceCreated(false)
				getGeofences()
			}, 250);
		}
	}, [geofenceDeleted, geofenceCreated])


	const getEventsPost = () => {
		const data = {
			limit: "500",
			onlywithposition: true
		}
		request.post("", "v2/events/list", data).then((response) => {
			const events = response.data.events
			setEvents(events)
		})
	}

	const getGeofences = () => {
		request.get("geofence/info").then((response) => {
			if(response.data && response.status === 200){
				setGeofences(response.data)
			}
		});
	}


	useEffect(() => {
		setCreatingGeo(false);
		if(location.pathname !== "/history" && (historyData || routesData)){
			setHistoryData()
			setRoutesData()
		}
		if(location.pathname !== "/events" && activeEvent){
			setActiveEvent()
		}
		if(map){
			switch(location.pathname){
				case "/devices":
					getDevices()
				break;
				case "/geofence":
					getGeofences()
				case "/history":
				break;
				default:
			}
		}
	}, [location, map])


	useEffect(() => {
		if (map) {
			mapToggles.forEach((toggle) => {
				switch (toggle.value) {
					case 1:
						if (toggle.active) {
							setActiveGroup(toggle.deviceArray)
						} else {
							setActiveGroup(null)
							getDevices();
						}
						break;
					case 2:
						if (toggle.active) {
							getEventsPost();
						//	getEvents();
						} else {
							setEvents([]);
						}
						break;
					case 3:
						if (toggle.active) {
							if(geofences.length === 0){
								getGeofences();
							}
						} else {
							if(location.pathname !== "/geofence"){
								setGeofences([])
							}
						}
						break;
					case 4: 
						setTraceUnit(toggle.active)
					break;
					case 5: 
						setHideOffline(toggle.active)
						break;
					case 6: 
						setCluster(toggle.active)
						break;
					case 7: 
						setShowDeviceName(toggle.active)
						break;
					default:
						break;
				}
			});
		}
	}, [mapToggles]);

	useEffect(() => {
		const geo = mapToggles.findIndex(toggle => toggle.value === 3)
		if(location.pathname !== "/geofence" && !mapToggles[geo].active){
			setGeofences([])
		}
	}, [location])


	useEffect(() => {
		if(activeDevice){
			let specificDevice = devices?.filter(device => device.id === activeDevice);
			if(map && specificDevice[0]){
				let lat = specificDevice[0].latitude
				let lng = specificDevice[0].longitude
				setOpenMarker(activeDevice)
				map.setZoom(15)
				map.panTo({lat, lng})
			}
		}
	}, [activeDevice])

	useEffect(() => {
		if(map && creatingGeo){
			const bounds = new window.google.maps.LatLngBounds();
			if(!initialGeoData){
				let lat = map.getCenter().lat()
				let lng = map.getCenter().lng()
				if(contextGeoMethod === "poly"){
					let path = [
						{
							lat: lat,
							lng: lng
						},
						{
							lat: lat - 0.01,
							lng: lng + 0.02
						},
						{
							lat: lat - 0.01,
							lng: lng - 0.02
						}
					]
					setPolyPathData(path)
					path.map((point) => {
						let lat = point.lat
						let lng = point.lng
						bounds.extend({
							lat: parseFloat(lat),
							lng: parseFloat(lng)
						})
						return bounds
					})
					if(bounds && map){
						map.fitBounds(bounds)
					}
				}else{
					setCircleData({
						center: {lat: lat, lng: lng},
						radius: 1000
					})
					let latLng = new window.google.maps.LatLng(lat, lng);
					let circle = new window.google.maps.Circle({radius: 1000, center: latLng});
					if(bounds && map){
						map.fitBounds(circle.getBounds())
					}
				}
			}else{
				if(contextGeoMethod === "poly"){
					if(initialGeoData){
						let path = []
						if(initialGeoData.center){
							path = [
								{
									lat: initialGeoData.center.lat,
									lng: initialGeoData.center.lng
								},
								{
									lat: initialGeoData.center.lat - 0.01,
									lng: initialGeoData.center.lng + 0.02
								},
								{
									lat: initialGeoData.center.lat - 0.01,
									lng: initialGeoData.center.lng - 0.02
								}
							]
						}else{
							path = initialGeoData.map(location => ({
								lat: location.latitude,
								lng: location.longitude
							}));
						}
						setPolyPathData(path)
						// HERE
						path.map((point) => {
							let lat = point.lat 
							let lng = point.lng
							bounds.extend({
								lat: parseFloat(lat),
								lng: parseFloat(lng)
							})
							return bounds
						})
						if(bounds && map){
							map.fitBounds(bounds)
						}
						// map.panTo(path[0])
					}
				}else{
					if(initialGeoData){
						if(!initialGeoData.center){
							setCircleData({
								radius: 1000,
								center: {lat: initialGeoData[0].latitude, lng: initialGeoData[0].longitude}
							})
							let latLng = new window.google.maps.LatLng(parseFloat(initialGeoData[0].latitude), parseFloat(initialGeoData[0].longitude));
							let circle = new window.google.maps.Circle({radius: 1000, center: latLng});
							if(bounds && map){
								map.fitBounds(circle.getBounds())
							}
							// map.panTo({lat: initialGeoData[0].latitude, lng: initialGeoData[0].longitude})
						}else{
							setCircleData({
								radius: parseFloat(initialGeoData.radius),
								center: initialGeoData.center
							})
							let latLng = new window.google.maps.LatLng(parseFloat(initialGeoData.center.lat), parseFloat(initialGeoData.center.lng));
							let circle = new window.google.maps.Circle({radius: parseFloat(initialGeoData.radius), center: latLng});
							if(bounds && map){
								map.fitBounds(circle.getBounds())
							}
							// map.panTo(initialGeoData.center)
						}

					}
				}
			}
			// if(creatingGeo){
			// 	map.setZoom(10)
			// }else{
			// 	map.setZoom(5)
			// }

		}
	}, [creatingGeo, initialGeoData, contextGeoMethod])

	const showEventOnMap = (alarm) => {
		return(
			<EventMarker 
			event={alarm} 
			isOpen={alarm.eventid === openEventMarker}
			clicked={() => setOpenEventMarker(openEventMarker === alarm.eventid ? -1 : alarm.eventid)}
			/>
		)
	}
	
	const showGeofencesOnMap = () => {		
		// if(creatingGeo){
		// 	return
		// }
		let circles = geofences?.filter(geofence => geofence.type == "CIRCLE")
		let polys = geofences?.filter(geofence => geofence.type == "POLYGON")
		return(
			<>
			{(circles).map((circle, index) => (	
				<div key={index} >
				<Circle
					options={{
					fillColor: mode==="light"?customColor:customColor,
					fillOpacity: "0.5",
					strokeColor: mode==="light"?customColor:customColor,
					strokeWeight: "2",
					clickable: false,
					}}
					center={{ lat: circle.latitude, lng: circle.longitude }}
					radius={parseFloat(circle.radius)}
					onMouseOut={() => setVisibleCircleGeo(null)}
					onMouseOver={() => setVisibleCircleGeo(index)}

				/>
				{(visibleCircleGeo === index || location.pathname === "/geofence" || showDeviceName) &&
					<InfoBox
						position={{lat: circle.latitude, lng: circle.longitude}}
						options={{
							disableAutoPan: true,
							closeBoxURL: "",
							boxStyle: {
								width: 'auto',
								boxShadow: '0 3px 18px -8px rgba(0,0,0,0.15)',
								borderRadius: "5px",
							},
							pixelOffset: new window.google.maps.Size(-25, -6)
						}}
					>
						<div className="bg-black p-1 d-flex align-items-center">
							<span className="text-white">{circle.name}</span>
						</div>
					</InfoBox>
				}
				</div>
			))}
			{(polys).map((poly, index) => {
				 let centroid = poly.points.reduce((acc, point) => {
					acc.lat += point.latitude;
					acc.lng += point.longitude;
					return acc;
				  }, {lat: 0, lng: 0});
				
				  centroid.lat /= poly.points.length;
				  centroid.lng /= poly.points.length;
				return(
				<div key={index}>
				 <Polygon
					options={{
						fillColor: mode==="light"?customColor:customColor,
						fillOpacity: "0.5",
						strokeColor: mode==="light"?customColor:customColor,
						strokeWeight: "2",
						clickable: false
					}}
					paths={poly.points.map(point => ({
						lat: point.latitude,
						lng: point.longitude
					}))}
					onMouseOut={() => setVisiblePolyGeo(null)}
					onMouseOver={() => setVisiblePolyGeo(index)}
				/>
				{(visiblePolyGeo === index || location.pathname === "/geofence" || showDeviceName) &&
					<InfoBox
						position={{lat: centroid.lat, lng: centroid.lng}}
						options={{
							disableAutoPan: true,
							closeBoxURL: "",
							boxStyle: {
								width: 'auto',
								boxShadow: '0 3px 18px -8px rgba(0,0,0,0.15)',
								borderRadius: "5px",
							},
							pixelOffset: new window.google.maps.Size(-25, -6)
						}}
					>
						<div className="bg-black p-1 d-flex align-items-center">
							<span className="text-white">{poly.name}</span>
						</div>
	
					</InfoBox>
				}		
				</div>
			)})}
			</>
		)
	}

	useEffect(() => {
		if(activeEvent && map){
			let lat = activeEvent.latitude
			let lng = activeEvent.longitude
			setOpenEventMarker(activeEvent.eventid)
			if(map.getZoom() < 15){
				map.setZoom(15)
			}
			map.panTo({lat, lng})
			map.panBy(0, 150)

		}

	}, [activeEvent])
	
	useEffect(() => {
		if(!activeGeo){
			return
		}

		const bounds = new window.google.maps.LatLngBounds();

		if(activeGeo.type == "POLYGON"){
			activeGeo.points.map((point) => {
				let lat = point.latitude != null ? point.latitude : 0
				let lng = point.longitude != null ? point.longitude : 0
				bounds.extend({
					lat: parseFloat(lat),
					lng: parseFloat(lng)
				})
				return bounds
			})
			if(bounds && map){

				map.fitBounds(bounds)
			}
		}else{
			let latLng = new window.google.maps.LatLng(parseFloat(activeGeo.latitude), parseFloat(activeGeo.longitude));
			let circle = new window.google.maps.Circle({radius: parseFloat(activeGeo.radius), center: latLng});
			if(bounds && map){
				map.fitBounds(circle.getBounds())
			}
		}

	}, [activeGeo])



	const updateData = useCallback(() => {
		try{
			request.get("devices/info").then((response) => {
				if (!response || !response.data || response.data.error) {
					return
				}
				const newData = response.data;
				const oldData = deviceListMap.length > 0 ? [...deviceListMap] : []; // Create a shallow copy
			
				// Compare newData with oldData and update only the differences
			  const updatedData = oldData.map((oldDevice) => {
				  const newDevice = newData?.find((newDev) => newDev.id === oldDevice.id);
				  if (newDevice) {
					// Compare properties of newDevice with oldDevice and update only the differences
					return {
					  ...oldDevice,
					  ...newDevice,
					};
				  }
				  return oldDevice;
			  });
				// Update deviceListMap with the updated data
				setDeviceListMap(updatedData);
			  });
		}catch (error){
		}

	  }, [deviceListMap]);


	const startInterval = useCallback(() => {
		if(location.pathname === "/subscription" || location.pathname === "/settings"){
			return
		}
		setLooping(true);
		intervalId = setInterval(updateData, 10000);
	  }, [updateData]);
	
	  const stopInterval = useCallback(() => {
		setLooping(false);
		clearInterval(intervalId);
	  }, []);
	
	  useEffect(() => {
		if (looping) {
		  startInterval();
		} else {
		  stopInterval();
		}
		return () => {
		  stopInterval(); // Cleanup function to stop interval when unmounting
		};
	  }, [looping, startInterval, stopInterval]);


	  useEffect(() => {
		const handleVisibilityChange = () => {
		  if (document.hidden) {
			stopInterval(); // Pause interval when window is in background
		  } else {
			if(location.pathname !== "/settings" && location.pathname !== "/subscriptions"){
				startInterval(); // Resume interval when window is in foreground
			}
		  }
		};
	  
		document.addEventListener("visibilitychange", handleVisibilityChange);
	  
		return () => {
		  document.removeEventListener("visibilitychange", handleVisibilityChange);
		  stopInterval(); // Cleanup function to stop interval when unmounting
		};
	  }, [startInterval, stopInterval]);


	
	  const onLoad = useCallback((map) => {
		setMap(map);
		setLooping(true); // Start interval when map loads
	  }, []);
	
	  const onUnmount = useCallback(() => {
		stopInterval(); // Stop interval when component unmounts
		setMap(null);
	  }, [stopInterval]);
	

	window.addEventListener("storage", function () {
		setMode(localStorage.getItem('theme'))
	}, false);

	const setBounds = () => {

		if(!devices && map){
		//	map.setZoom(10)
			return
		}
		if(map && devices?.length > 0){
			const bounds = new window.google.maps.LatLngBounds();
			devices.length > 0 && devices.map((devices, index) => {
				var lat = devices.latitude != null ? devices.latitude : 0
				var lng = devices.longitude != null ? devices.longitude : 0
					bounds.extend({
						lat: parseFloat(lat),
						lng: parseFloat(lng),
					})
					return bounds
			})
			if(bounds && map){
				map.fitBounds(bounds);				
				if(devices?.length === 1){
					setTimeout(() => {
						map.setZoom(15) 	
					}, 0);
				}
			}
		}
	}

	

	const expandMap = () => {
		const map = document.querySelector(".map_style");
		const mapExpanded = document.querySelector(".map_style_expanded");
		const info = document.querySelector(".section-left_bottom_data");
		const infoExpanded = document.querySelector(".section-left_bottom_data_expanded");
		const statsCard = document.querySelector(".map_statistics_detail");
		const statsCardExtended = document.querySelector(".map_statistics_detail_expanded");
		if (map === null) {
		  infoExpanded?.classList.replace("section-left_bottom_data_expanded", "section-left_bottom_data");
		  if (mapExpanded) {
			mapExpanded?.classList.replace("map_style_expanded", "map_style");
		  }
		  if (statsCardExtended) {
			statsCardExtended?.classList.replace("map_statistics_detail_expanded", "map_statistics_detail");
		  }
		} else {
		  info?.classList.replace("section-left_bottom_data", "section-left_bottom_data_expanded");
		  if (map) {
			map?.classList.replace("map_style", "map_style_expanded");
		  }
		  if (statsCard) {
			statsCard?.classList.replace("map_statistics_detail", "map_statistics_detail_expanded");
		  }
		}
	  };



	const onEditCircle = useCallback(() => {
		if (circleRef.current) {
			const center = circleRef.current.getCenter();
			const radius = circleRef.current.getRadius();
			setCircleData(prevCircleData => {
				const hasCenterChanged = prevCircleData.center.lat !== center.lat() || prevCircleData.center.lng !== center.lng();
				const hasRadiusChanged = prevCircleData.radius !== radius;
				if (hasCenterChanged || hasRadiusChanged) {
					return {
						center: {lat: center.lat(), lng: center.lng()},
						radius: radius
					};
				}
				return prevCircleData;
			});
		}
	}, [setCircleData]);
	
	  // Bind refs to current Polygon and listeners
	  const onLoadCircle = useCallback(
		circle => {
			circleRef.current = circle;
		},
		[onEditCircle]
	  );
	
	  // Clean up refs
	  const onUnmountCircle = useCallback(() => {
		listenersCircleRef.current.forEach(lis => lis.remove());
		circleRef.current = null;
	  }, []);

	const creatingCircle = () => {
		return(
			<Circle
				options={{
					fillColor: mode==="light"?customActiveColor:customActiveColor,
					fillOpacity: "0.5",
					strokeColor: mode==="light"?customActiveColor:customActiveColor,
					strokeWeight: "2",
				}}
				editable
				//draggable
				center={circleData && circleData.center}
            	radius={circleData && circleData.radius}
				onRadiusChanged={onEditCircle}
				onCenterChanged={onEditCircle}
				onMouseUp={onEditCircle}
				onDragEnd={onEditCircle}
				onLoad={onLoadCircle}
				onUnmount={onUnmountCircle}
			>
			</Circle>
		)
	}

	const onEditPolygon = useCallback(() => {
		if (polygonRef.current) {
		  const nextPath = polygonRef.current
			.getPath()
			.getArray()
			.map(latLng => {
			  return { lat: latLng.lat(), lng: latLng.lng() };
			});
		  setPolyPathData(nextPath);
		}
	  }, [setPolyPathData]);
	
	  // Bind refs to current Polygon and listeners
	  const onLoadPoly = useCallback(
		polygon => {
		  polygonRef.current = polygon;
		  const path = polygon.getPath();
		  listenersRef.current.push(
			path.addListener("set_at", onEditPolygon),
			path.addListener("insert_at", onEditPolygon),
			path.addListener("remove_at", onEditPolygon)
		  );
		},
		[onEditPolygon]
	  );
	
	  // Clean up refs
	  const onUnmountPoly = useCallback(() => {
		listenersRef.current.forEach(lis => lis.remove());
		polygonRef.current = null;
	  }, []);

	const creatingPoly = () => {
		return(
		<Polygon
			options={{
				fillColor: mode==="light"?customActiveColor:customActiveColor,
				fillOpacity: "0.5",
				strokeColor: mode==="light"?customActiveColor:customActiveColor,
				strokeWeight: "2",
			}}
			editable
            draggable
            path={polyPathData}
            onMouseUp={onEditPolygon}
            onDragEnd={onEditPolygon}
            onLoad={onLoadPoly}
            onUnmount={onUnmountPoly}
		>
		</Polygon>
		)
	}

	useEffect(() => {
		if(contextSaveGeofence){
			if(contextGeoMethod === "poly"){
				updateGeoData(polyPathData)
			}else{
				updateGeoData(circleData)
			}
		}
	}, [contextSaveGeofence])

	const showPolyInfo = (e, polylineBro) => {
		let clickLat = e.latLng.lat()
		let clickLng = e.latLng.lng()
		let closestPoint = historyPolyLine[0]
		let minDist = Number.MAX_VALUE;

		for (let point of polylineBro){
			let dist = haversineDistance(clickLat, clickLng, point.lat, point.lng);
			if (dist < minDist) {
				minDist = dist;
				closestPoint = point;
			}
		}
		if(closestHistoryPoint !== closestPoint){
			setClosestHistoryPoint(closestPoint)
			map.panTo({lat: closestPoint.lat, lng: closestPoint.lng})
		}else{
			setClosestHistoryPoint(null)
		}
	}


	useEffect(() => {
		if(historyPolyLine && map){
			const bounds = new window.google.maps.LatLngBounds();
			historyPolyLine.map((point, index) => {
				var lat = point.lat != null && point.lat
				var lng = point.lng != null && point.lng
				bounds.extend({
					lat: parseFloat(lat),
					lng: parseFloat(lng),
				})
				return bounds
			})
			if(bounds && map){
				map.fitBounds(bounds);
			}
		}	
	}, [historyPolyLine])


	const haversineDistance = (lat1, lng1, lat2, lng2) => {
		const toRad = (x) => (x * Math.PI) / 180;
		const R = 6371; // Earth radius in km
  
		const dLat = toRad(lat2 - lat1);
		const dLng = toRad(lng2 - lng1);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				  Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
				  Math.sin(dLng / 2) * Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c; // Distance in km
	};

	const polyLineContent = useMemo(() => {
		if (!historyPolyLine || historyPolyLine.length === 0) return null;
		return(
			<>
			<MarkerF
				icon={{
					url: markers.history_start,
					size: new window.google.maps.Size(34, 56), // Setting the size here
					scaledSize: new window.google.maps.Size(34, 56), // Optional, for scaling the icon
					anchor: new window.google.maps.Point(17, 56) // Set the anchor point to the center of the icon
				}}
				onClick={(e) => showPolyInfo(e, [historyPolyLine[0], historyPolyLine[0]])}
				position={{ lat: historyPolyLine[0].lat, lng: historyPolyLine[0].lng }}
			/>
			<MarkerF
				icon={{
					url: markers.history_stop,
					size: new window.google.maps.Size(34, 56), // Setting the size here
					scaledSize: new window.google.maps.Size(34, 56), // Optional, for scaling the icon
					anchor: new window.google.maps.Point(17, 56) // Set the anchor point to the center of the icon
				}}
				onClick={(e) => showPolyInfo(e, [historyPolyLine[historyPolyLine.length-1], historyPolyLine[historyPolyLine.length-1]])}
				position={{ lat: historyPolyLine[historyPolyLine.length-1].lat, lng: historyPolyLine[historyPolyLine.length-1].lng }}
			/>
			{historyPolyLine.map((point, index) => {
				let polylinePoints;
				let elements = [];
				/* OM VI VILL HA 0 SPEED UTRITADE */

				if (index === 0) {
					// For the first polyline, include the first point and the next two points
					polylinePoints = [point, historyPolyLine[index + 1], historyPolyLine[index + 2]];
				} else if (index === historyPolyLine.length - 2) {
					// For the second last point, include this point, the next point, and the last point
					polylinePoints = [historyPolyLine[index - 1], point, historyPolyLine[index + 1]];
				} else if (index === historyPolyLine.length - 1) {
					// Skip the last point, as it's already included in the polyline of the second last point
					return null;
				} else {
					// For all other points, include this point and the next point
					polylinePoints = [point, historyPolyLine[index + 1]];
				}

				const polylineElement = (
					<Polyline
					  key={`polyline-${index}`}
					  options={{
						fillColor: mode === "light" || mode === "terrain" ? customColor : customColor,
						fillOpacity: "1",
						strokeColor: mode === "light" || mode === "terrain" ? customColor : customColor,
						strokeWeight: "4",
						geodesic: false,
					  }}
					  onClick={(e) => showPolyInfo(e, polylinePoints)}
					  path={polylinePoints}
					/>
				);

				elements.push(polylineElement); // Add polyline to elements array

				if(point.speed === 0 && map){
					const markerElement = (
					   <MarkerF
						 key={`marker-${index}`}
						 icon={{
						   url: icons.stopped_marker,
						   size: new window.google.maps.Size(8, 8), // Setting the size here
						   scaledSize: new window.google.maps.Size(8, 8), // Optional, for scaling the icon
						   anchor: new window.google.maps.Point(4, 4) // Set the anchor point to the center of the icon
						 }}
						 onClick={(e) => showPolyInfo(e, polylinePoints)}
						 position={{ lat: point.lat, lng: point.lng }}
					   />
					);
					elements.push(markerElement); // Add marker to elements array
				}	
				return elements
			})}
			</>
		)
	}, [historyPolyLine, mode]);


	useEffect(() => {
		if(routePoint !== -1 && map){
			setClosestHistoryPoint(historyPolyLine[routePoint])
			map.panTo({lat: historyPolyLine[routePoint].lat, lng: historyPolyLine[routePoint].lng})
		}else{
			setClosestHistoryPoint(null)
		}
	}, [routePoint])

	const eventsOnMap = useMemo(() => {
		if(!events || events.length === 0){ return null}
		return(
		events && events.map((event, index) => {
			return (
				<EventMarker
				key={index}
				event={event} 
				isOpen={event.eventid === openEventMarker}
				clicked={() => setOpenEventMarker(openEventMarker === event.eventid ? -1 : event.eventid)}
				/>
			)	
		})
		)
	}, [events, openEventMarker])

	useEffect(() => {
		if(openMarker === -1){
			setActiveDevice(null)
		}
	}, [openMarker])




	return (isLoaded && devices !== null) && (
		<div className={`${MOBILE ? "mobile-map" : "section-right_data"}`}>
			<div className="map_style">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={2}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={options}
				
			>	
			<MapSettings />
			{/* EXPAND MAP BUTTON */}
			<button className={`btn expand_map ${MOBILE ? "d-none" : ""}`} onClick={() => expandMap ()}>
				<img src={icons.expand} className="expand_map_icon white-icon cursor-pointer" alt="..." />
			</button>
			{/*SHOW DEVICES*/}
			{cluster 
				?
				<MarkerClusterer
					averageCenter
					gridSize={40}
					maxZoom={15}
					minimumClusterSize={2}
					styles={clusterStyles}
				>
					{(clusterer) => (
						devices?.length > 0 &&
						devices?.map((device) => {
							return (
								<DeviceMarker
									hideOffline={hideOffline}
									clusterer={clusterer}
									key={device.id}
									device={device}
									isOpen={device.id === openMarker}
									clicked={() => setOpenMarker(openMarker === device.id ? -1 : device.id)}
									showName={showDeviceName}
								/>
							)	
						})
					)}
				</MarkerClusterer>
				:
				
				devices?.length > 0 &&
				devices?.map((device) => {					
					return (
						<DeviceMarker
						hideOffline={hideOffline}
						activeDevice={activeDevice}
						key={device.id}
						device={device}
						isOpen={device.id === openMarker}
						clicked={() => setOpenMarker(openMarker === device.id ? -1 : device.id)}
						showName={showDeviceName}
						/>
					)	
				})
			}

			{traceUnit && Object.entries(tracingPositions).map((lol, index) => (
				lol[1].length > 1 &&
					<Polyline
						key={`polyline-${index}`}
						options={{
						fillColor: mode === "light" || mode === "terrain" ? customColor : customColor,
						fillOpacity: "1",
						strokeColor: mode === "light" || mode === "terrain" ? customColor : customColor,
						strokeWeight: "4",
						geodesic: false,
						}}
						path={lol[1]}
					/>
				)
			)}


			{/*SHOW SPECIFIC EVENT ON MAP*/}
			{activeEvent &&
				showEventOnMap(activeEvent)
			}
			{/* SHOW GEOFENCES ON MAP*/}
			{showGeofencesOnMap()}
			{/* SHOW CREATING GEOFENCE*/}
			{creatingGeo && contextGeoMethod === "poly"
				? creatingPoly()
				: creatingGeo && contextGeoMethod === "circle"
				? creatingCircle()
				: null
			}
			{/* SHOW EVENTS ON MAP */}
			{eventsOnMap}

			{polyLineContent}
			{closestHistoryPoint && polyLineContent &&
				<HistoryMarker point={closestHistoryPoint} closeWindow={() => setClosestHistoryPoint(null)} />
			}
			<></>
			</GoogleMap>
			</div>
		</div>
	)
}

export default Map;
