import React, { useState, useEffect } from 'react';
import { copyToClipboard, fixDate, getEventIcon, getEventIconMap, getMapMarker, translateEvents } from '../../helpers/helperFunctions';
import { MarkerF, InfoBox } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { icons } from "../../helpers/constants";
import Button from '../Buttons/Button';


const EventMarker = (props) => {
    const { t } = useTranslation();

    const [event, setEvent] = useState(null)
    const [copy, setCopy] = useState(t("map.copy_position"))
    const [address, setAddress] = useState(t("map.calculate_address"))

    useEffect(() => {
        setEvent(props.event)
    },[props.event])

    const copyPosition = (lat, lng) => {
        let data = `https://www.google.com/maps/place/${lat},${lng}`;
        copyToClipboard(data)
            .then(result => {
                setCopy(t("map.copy_successful"))
            })
            .catch(error => {
                setCopy(t("map.copy_failed"))
            });

            setTimeout(() => {
                setCopy(t("map.copy_position"))
            }, 2000);
    }

    const calculateAddress = (lat, lng) => {
        if(address === t("map.calculate_address")){
            const geocoder = new window.google.maps.Geocoder();
            const latandLng = new window.google.maps.LatLng(lat, lng);
            geocoder.geocode({ location: { lat: event.latitude, lng: event.longitude } }, (results, status) => {
                if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0].formatted_address)
                } else {
                    return
                }
                } else {
                    return
                }
            })
        }
    }

    return (
        event &&
        <>
            <MarkerF
				position={{lat: event.latitude, lng: event.longitude}}
				icon={getEventIconMap(event.type)}
                onClick={props.clicked}
			>
            {props.isOpen &&
                <InfoBox
                    position={{ lat: event.latitude, lng: event.longitude }}
                    options={{
                        closeBoxURL: "",
                        boxStyle: {
                            width: '260',
                            boxShadow: '0 3px 18px -8px rgba(0,0,0,0.15)',
                            borderRadius: "5px",
                        },
                        pixelOffset: new window.google.maps.Size(-130, 0)
                    }}
                >
                    <div className="d-flex flex-column event-info-box">
                        <div className="d-flex flex-row justify-content-between align-items-center border-settings p-3 bg-white">
                            <div className="d-flex flex-row align-items-center w-75">
                                <h4 className="me-3 text-truncate">{event.devicenameorfrom}</h4>
                            </div>
                            <img className="cursor-pointer" src={icons.close_gray} onClick={props.clicked} alt="..." /> 
                        </div>
                        <div className="d-flex flex-column px-2 bg-white py-2">
                                <div className="d-flex flex-row">
                                    <img className="size_25 me-2" src={icons.sidebar.bell_active} alt=''/>
                                    <div className="d-flex flex-column align-items-start">
                                        <p className="bold">{t("map.event_type")}</p>
                                        {/* <p>{translateEvents(t, event.type)}</p> */}
                                        <p>{event.typedesc}</p>
                                    </div>
                                    <img className="size_25 mx-2" src={icons.clock} alt=''/>
                                    <div className="d-flex flex-column align-items-start">
                                        <p className="bold">{t("map.time_of_alarm")}</p>
                                        <p>{fixDate(event.date, false, true)}</p>
                                    </div>
                                </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="d-flex flex-row">
                                    <img className="size_25 me-2" src={icons.marker} alt=''/>
                                    <div className="d-flex flex-column align-items-start">
                                        <p className="bold">{t("map.position")}</p>
                                        <p>{event.latitude.toFixed(5)}, {event.longitude.toFixed(5)}</p>
                                        <p onClick={() => calculateAddress(event.latitude, event.longitude)}className="cursor-pointer">{address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button btnClass="btn-primary" onClick={() => copyPosition(event.latitude, event.longitude)}>{copy}</Button>
                    </div>
                </InfoBox>
                }
			</MarkerF>
          
        </>
    )
}

export default EventMarker;