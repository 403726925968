import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import { useIsMobile } from '../../helpers/customHooks';
import Button from '../Buttons/Button';

const DeleteGeofenceModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show ?? false)
    const [fences, setFences] = useState(null)

    useEffect(() => {
        setShow(props.show)
        setFences(props.selectedFences)
    },[props.show])

    
    const handleClose = () => {
        props.onClose()
    }

    const returnFenceIds = () => {
		let checked = fences?.filter(fence => fence.selected).map(fence => parseInt(fence.id));
		return checked
	}


    return(
        <>
        <Dialog open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.geofence} alt=""/>
                            <h3 className="ms-2">{t("geofence.delete_geofence")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." />
                    </div>
                    <div className="p-3">
                        <p className="mb-2">{t("geofence.delete_geofence_copy1")}</p>
                        {fences?.map((fence, index) => (
                            <p key={index} className="bold ms-2">{fence.name}</p>
                        ))}
                        <p className="mt-2">{t("geofence.delete_geofence_copy2")}</p>
                    </div>
                    <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                    <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("geofence.cancel")}</Button>
                    <Button onClick={() => props.deleteFences(returnFenceIds())} btnClass="btn-danger">{t("geofence.delete_geofence")}</Button>
            </div>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default DeleteGeofenceModal;